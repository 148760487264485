.company_info {
  border-top: 1px solid rgb(89, 89, 89);
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  ul {
    text-align: center;
    li {
      display: inline-block;
      color: $secondary-color;
      font-size: 15px;
      @media (max-width: 767px) {
        display: block;
        padding-bottom: 15px;
        &:nth-child(4),
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
.social_media_footer {
  padding-bottom: 10px;
  ul {
    text-align: center;
    li {
      display: inline-block;
      a {
        color: $primary-color;
        display: inline-block;
        padding: 0 15px;
        -webkit-transition: all 300ms ease-in;
        -moz-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        &:hover {
          color: $secondary-color;
        }
        svg {
          font-size: 22px;
        }
      }
    }
  }
}
