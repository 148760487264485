.header {
  padding-top: 20px;
  .navbar {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(89, 89, 89);
    margin-bottom: 40px;
    // padding-bottom: 20px;
    .navbar-brand {
      img {
        height: 60px;
        @media (max-width: 991px) {
          height: 50px;
        }
      }
    }
    .navbar-nav {
      .nav-link {
        color: $primary-color;
        text-transform: uppercase;
        font-size: 14px;
        padding: 0 20px;
        position: relative;
        -webkit-transition: all 200ms ease-in;
        -moz-transition: all 200ms ease-in;
        -o-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
        @media (max-width: 991px) {
          padding-top: 20px !important;
        }
        &:hover {
          color: $secondary-color;
        }
        &::after {
          position: absolute;
          width: 80%;
          border-bottom: 1px solid #000000;
          content: "";
          left: 50%;
          bottom: -10px;
          opacity: 0;
          transform: translateX(-50%);
          -webkit-transition: all 200ms ease-in;
          -moz-transition: all 200ms ease-in;
          -o-transition: all 200ms ease-in;
          transition: all 200ms ease-in;
        }
        &:hover {
          &::after {
            opacity: 1;
            @media (max-width: 991px) {
              display: none;
            }
          }
        }
        &.active {
          color: $secondary-color;
          &::after {
            border-bottom: 1px solid transparent;
            @media (max-width: 991px) {
              display: none;
            }
          }
        }
      }
    }
  }
}
.navbar-collapse.showMenu {
  display: block;
}
.navbar-toggler {
  background: #b5b5b5;
  &.active {
    background-color: #c55a11;
  }
}
@media (max-width: 991px) {
  .navbar-toggler {
    font-size: 14px;
  }
  .nav-item {
    &:last-child {
      .nav-link {
        padding-bottom: 20px !important;
      }
    }
  }
}
