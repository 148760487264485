.about_content {
  p {
    margin-top: 20px;
    line-height: 28px;
    text-align: justify;
    @media (max-width: 767px) {
      text-align: center;
      line-height: 30px;
      font-size: 15px;
    }
  }
}
.team_container {
  margin-bottom: 70px;
  margin-top: 20px;
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
  .row {
    .col-md-3 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .team_item {
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    figure {
      height: 250px;
      margin-bottom: 10px;
      img {
        height: 100%;
        object-fit: cover;
        object-position: top center;
        width: 100%;
      }
    }
    p {
      font-size: 15px;
      line-height: 26px;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}
