body {
  margin: 0px;
  padding: 0px;
  font-family: $base-font;
  position: relative;
  width: 100%;
  color: rgb(89, 89, 89);
}

img {
  max-width: 100%;
}

a {
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
figure,
a,
section {
  margin: 0px;
  padding: 0px;
}
ul {
  margin: 0px;
  list-style: none;
}
.innerpage_content {
  padding-bottom: 40px;
}
