.home_tabs {
  ul {
    text-align: center;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    li {
      display: inline-block;
      color: #212121;
      &.active {
        a {
          color: rgb(197, 90, 17);
        }
      }
      a {
        color: #212121;
        text-transform: uppercase;
        display: inline-block;
        padding: 0 15px;
      }
    }
  }
}
.home_product_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  .row {
    .col-md-4 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .product_item {
    margin-bottom: 10px;
    position: relative;
    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
    &:hover {
      .product_item_content {
        opacity: 1 !important;
      }
      figure {
        img {
          transform: scale(1.1);
        }
      }
    }
    .product_item_content {
      position: absolute;
      bottom: 0px;
      width: 100%;
      background: rgba($color: #ffffff, $alpha: 0.9);
      padding: 10px 15px;
      -webkit-transition: all 500ms ease-in-out;
      -moz-transition: all 500ms ease-in-out;
      -o-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
      opacity: 0;
      h4 {
        font-size: 16px;
        color: #000000;
        text-transform: uppercase;
        font-weight: 500;
        a {
          color: #000000;
        }
      }
    }
    figure {
      height: 370px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top top;
        -webkit-transition: all 500ms ease-in-out;
        -moz-transition: all 500ms ease-in-out;
        -o-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
      }
    }
  }
}
.services_content {
  margin-bottom: 40px;
  .list {
    & > li {
      padding-bottom: 30px;
      @media (max-width: 767px) {
        font-size: 15px;
      }
      ul {
        padding-left: 20px;
        li {
          padding-top: 5px;
        }
      }
    }
  }
}
.media_content {
  margin-bottom: 60px;
  .row {
    .col-md-3 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .gallery_item {
    height: 270px;
    overflow: hidden;
    position: relative;
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      border: 0px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      opacity: 0;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition: all 300ms ease-in-out;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      svg {
        color: #000000;
        font-size: 16px;
      }
    }
    &::after {
      position: absolute;
      content: "";
      left: 0px;
      top: 0px;
      width: 100%;
      opacity: 0;
      height: 100%;
      -webkit-transition: all 300ms ease-in-out;
      -moz-transition: all 300ms ease-in-out;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      background: rgba($color: #000000, $alpha: 0.4);
    }
    &:hover {
      &::after {
        opacity: 1;
      }
      button {
        opacity: 1;
      }
    }
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
    img {
      height: 100%;
      width: 100%;
      object-position: center center;
      object-fit: cover;
    }
  }
}
.testimonials_content {
  margin-bottom: 40px;
  @media (max-width: 767px) {
    .section_title {
      text-align: center;
      h3 {
        line-height: 40px;
      }
    }
  }
  ul {
    li {
      padding-bottom: 30px;
      p {
        line-height: 30px;
        position: relative;
        padding-left: 40px;
        padding-right: 25px;
        &::before {
          position: absolute;
          top: -10px;
          left: 0px;
          content: "\f10d";
          font-family: FontAwesome;
          color: #d7d7d7;
          font-size: 24px;
          font-weight: 300;
        }
        &::after {
          position: absolute;
          top: -10px;
          right: 0px;
          content: "\f10e";
          font-family: FontAwesome;
          color: #d7d7d7;
          font-weight: 300;
          font-size: 24px;
        }
      }
      span {
        text-align: right;
        display: block;
        margin-bottom: 40px;
        padding-right: 25px;
      }
    }
  }
}
.section_title {
  h3 {
    font-size: 19px;
    margin-bottom: 60px;
    margin-top: 20px;
  }
}
.clients_items_wrapper {
  margin-top: 40px;

  ul {
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    li {
      float: left;
      width: 25%;
      text-align: center;
      box-sizing: border-box;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 767px) {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
        &:nth-child(2n) {
          border-right: 0px;
        }
      }
      &:nth-child(4) {
        border-right: 0px;
      }
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(5) {
        border-bottom: 0px;
      }
    }
  }
}
.clients_content {
  margin-bottom: 60px;
}
.contact_content {
  margin-bottom: 40px;
}
.contact_info {
  ul {
    li {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      font-size: 18px;
      color: #c55a11;
      @media (max-width: 767px) {
        font-size: 16px;
      }
      svg {
        font-size: 46px;
        margin-right: 25px;
        color: #555;
      }
    }
  }
}
.contact_form {
  margin-top: 20px;
  margin-bottom: 60px;
  .submit-btn {
    height: 50px;
    padding: 0 30px;
    border: 0px;
    background-color: $primary-color;
    color: #ffffff;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    &:hover {
      background-color: $secondary-color;
    }
  }
  .form-group {
    @media (max-width: 767px) {
      label {
        font-size: 14px;
      }
    }
    .form-control {
      height: 45px;
      border-radius: 0px;
    }
    textarea {
      &.form-control {
        height: 150px;
      }
    }
  }
}
.work_content_detail {
  margin-bottom: 60px;
  .work_detail {
    position: sticky;
    top: 50px;
    @media (max-width: 767px) {
      position: unset;
    }
  }
  p {
    line-height: 30px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      text-align: center;
      font-size: 15px;
    }
  }
  .work_detail_title {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 100px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        line-height: 40px;
      }
    }
    span {
      color: #555555;
    }
  }
  .work_img {
    ul {
      li {
        margin-bottom: 10px;
        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
.work_social_media {
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  ul {
    li {
      display: inline-block;
      a {
        color: #595959;
        display: block;
        padding: 0 10px;
        transition: all 300ms ease-in;
        svg {
          font-size: 20px;
        }
        &:hover {
          color: #c55a11;
        }
      }
    }
  }
}
.next_prev_icon {
  text-align: right;
  margin-top: 20px;
  ul {
    li {
      display: inline-block;
      cursor: pointer;
      svg {
        font-size: 50px;
        color: #928f8f;
      }
    }
  }
}
.react-tabs {
  .react-tabs__tab-list {
    border: 0px;
    text-align: center;
    margin-bottom: 40px;
  }
  .react-tabs__tab--selected {
    border-radius: 0px;
    border: 0px;
    color: #c55a11;
  }
  .react-tabs__tab {
    text-transform: uppercase;
    &:first-child {
      position: relative;
      padding-right: 25px;
      &::after {
        position: absolute;
        content: "|";
        color: #000000;
        right: 0px;
      }
    }
  }
}
.react-tabs__tab:focus:after {
  // position: unset !important;
  height: unset !important;
  bottom: unset !important;
  left: unset !important;
}
.react-tabs__tab:focus {
  box-shadow: none;
}
.subscribe_box {
  background: #f7f7f7;
  // max-width: 300px;
  color: #ffffff;
  padding: 20px;
  // position: absolute;
  // bottom: 50px;
  // right: 30px;
  width: 100%;
  .form-control {
    height: 50px;
    font-size: 15px;
    border: 0px;
    border-radius: 0px;
    background: #ffffff;
    text-align: center;
  }
  button {
    width: 100%;
    height: 50px;
    text-transform: capitalize;
    border: 0px;
    font-size: 15px;
    // font-weight: 600;
    background-color: #595959;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    color: #ffffff;
    &:hover {
      background-color: $secondary-color;
      color: #ffffff;
    }
  }
}
@media (max-width: 767px) {
  .client_completed {
    p {
      padding-top: 0px !important;
    }
  }
}
.testimonial_slider {
  background-color: #f2f2f2;
  padding-top: 80px;
  padding-bottom: 120px;
  margin-bottom: 60px;
  .testimonial_arrow {
    bottom: -75px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    svg {
      font-size: 65px;
    }
  }
  .tesimonial_prev {
    right: 70px !important;
  }
  .tesimonial_next {
    right: 0px !important;
  }
}
.testimonail_slider_image {
  margin: 0 5px;
}
